<template>
  <signup-template class="sdr-schedule" :progress-amount="100" data-test="layout">
    <template #text>
      <h1 class="sdr-schedule__title" data-test="title">
        {{ $t(`${langPath}.title`) }}
      </h1>
      <h3 class="sdr-schedule__description" data-test="description">
        {{ $t(`${langPath}.description`) }}
      </h3>
      <signup-switch-to-english />
    </template>

    <template #form>
      <section class="sdr-schedule__form">
        <div
          class="calendly-inline-widget"
          :data-url="calendlyUrl"
          data-test="calendly-component"
        />
      </section>
    </template>
  </signup-template>
</template>
<script>
import SignupTemplate from './components/SignupTemplate'
import SignupSwitchToEnglish from './components/SignupSwitchToEnglish'

import { sdrScheduleUrl } from '@/helpers/constants/external-urls'

export default {
  name: 'SignupSdrSchedule',

  components: {
    SignupTemplate,
    SignupSwitchToEnglish
  },

  data () {
    return {
      langPath: __langpath,
      calendlyUrl: `${sdrScheduleUrl}?hide_event_type_details=1&hide_gdpr_banner=1`,
      calendlyScriptElement: undefined
    }
  },

  mounted () {
    this.calendlyScriptElement = document.createElement('script')
    this.calendlyScriptElement.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    )

    document.head.appendChild(this.calendlyScriptElement)
  },

  beforeDestroy () {
    document.head.removeChild(this.calendlyScriptElement)
  }
}
</script>

<style lang="scss" scoped>
.sdr-schedule {
  &__image {
    @include margin(bottom, 10);
    @include margin(top, 10);

    display: block;
  }

  &__title {
    @include margin(bottom, 7);

    color: var(--tx-neutral-blank);
    font: var(--tx-title-1);
    max-width: $base * 127;
  }

  &__description {
    color: var(--tx-neutral-blank);
    font: var(--tx-title-3);
    font-size: 22px;
    font-weight: 400;
    line-height: $base * 7;
    max-width: $base * 119;
  }

  &__form {
    background-color: var(--bg-neutral-blank);
    height: 100vh;
    padding-top: 10px;
  }

  .calendly-inline-widget {
    height: 100vh;
  }

  @include breakpoint(md) {
    .calendly-inline-widget {
      height: 100vh;
      padding-top: 10px;
    }
  }

  @include breakpoint(lg) {
    .calendly-inline-widget {
      height: 100vh;
      padding-top: 10px;
    }
  }
}
</style>
